import React from 'react'
import PropTypes from 'prop-types'
import CountUp from 'react-countup'
import './style.scss'

class CountNumber extends React.Component {
  constructor(props) {
    super(props)
    this.countNumber = React.createRef()
    this.countUp = React.createRef()
    this.state = { countStarted: false }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const { countStarted } = this.state
    const eTop = $(this.countNumber.current).offset().top
    const wHeight = $(window).height()
    const scrollTop = $(window).scrollTop()

    if (
      !countStarted &&
      this.countUp.current &&
      scrollTop >= eTop - wHeight / 2
    ) {
      this.setState({
        countStarted: true,
      })
      this.countUp.current.restart()
    }
  }

  render() {
    const { counter_id, number, before, after, title, seperator } = this.props
    var decimals = number.countDecimals()
    return (
      <div className="dhsv_vc_count_number counter" ref={this.countNumber}>
        {number && (
          <CountUp
            className="counter__number"
            decimal=","
            decimals={decimals}
            duration={3}
            end={number}
            id={`number_${counter_id}`}
            ref={this.countUp}
            separator={seperator ? '.' : ''}
            start={number}
            suffix={`<span class="number-suffix">${after}</span>`}
            prefix={`<span class="number-prefix">${before}</span>`}
            useEasing={true}
            useGrouping={true}
          />
        )}
        {title && (
          <p
            className="counter__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
      </div>
    )
  }
}

CountNumber.propTypes = {
  counter_id: PropTypes.string,
  number: PropTypes.number,
  after: PropTypes.string,
  before: PropTypes.string,
  title: PropTypes.string,
  counting: PropTypes.bool,
  seperator: PropTypes.bool,
}

export default CountNumber

Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0
  return this.toString().split('.')[1].length || 0
}
